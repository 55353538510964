.fixed-bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.cart-icon {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #4c0583; /* Add this line to apply a border */
}

.cart-icon-image {
    margin-right: 10px;
}


.medicines-list::-webkit-scrollbar {
    width: 5px; /* Decrease the width of the scrollbar */
}

.medicines-list::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.medicines-list::-webkit-scrollbar-thumb {
    background-color: #050a4e; /* Color of the scrollbar handle */
    border-radius: 10px; /* Round the corners of the scrollbar handle */
}

.medicines-list::-webkit-scrollbar-thumb:hover {
    background: #050a4e; /* Color of the scrollbar handle on hover */
}
.empty-cart-message {
    text-align: center;
    margin-top: 50px; /* Adjust the margin top to center vertically */
    margin-bottom: 50px; /* Adjust the margin top to center vertically */

    font-size: 24px;
    color: #888;
}

.empty-cart-message p {
    margin: 0;
}

.address-container .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.address-container .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.address-container .button-group {
    display: flex;
    justify-content: space-between;
}
/* Add this CSS to your CategoryMedicines.css */
.selected-card {
    border: 2px solid #6206a8; /* Adjust the color and border style as needed */
}
.pricing-card:hover{
    cursor: pointer !important;
}

