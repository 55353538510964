.collapse-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-out;
}

.collapse-content.show {
    max-height: 1000px; /* A large enough value to cover the content height */
    transition: max-height 1s ease-in;
}

.fixed-bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.cart-icon {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #4c0583; /* Add this line to apply a border */
}

.cart-icon-image {
    margin-right: 10px;
}
